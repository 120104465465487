import React from 'react';
import PageHead from './pagecomponent/PageHead';

const content = ([
             "Each quantamental strategy has distinct effectiveness in generating superior return under different situation"
             ,"By employing suitable strategy on specific asset class for each situation could potentially reap optimal investment outcome"
             ,"In a nutshell, Cauldrons focus on combined strategies' compatibility under each market condition to provide consistant & stable financial returns"
             ,"Applied methods include econometric, machine-learning & financial analysis et cetera"
                ])

const Philosophy = () => {
    return (
            <div>
            <PageHead 
            subject= 'Investment Philosophy'
            contents = {content}
            />
        </div>
    );
}

export default Philosophy;