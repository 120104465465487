import React from 'react';
import PageHead from './pagecomponent/PageHead';

const content = ([
                  'Part-time Cashier & Night-time Quantamental Practitioner'
                  ,'Self-taught Technologist in Node.js, Python and Linux OS'
                  ,'Applied Research on Statistical, Volatility, Value & Growth as well as Global Macro Investment Strategy'
                  ,'While squeezing remaining time to read books with good review'
                    ])

const Profile = () => {
    return (
    <div>
        <PageHead 
            subject= 'About Me'
            contents = {content}
             />
        </div>
    );
}

export default Profile;